import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
    name: "peers",
    initialState: {
        all: {},
        selected: null,
    },
    reducers: {
        // action => action handler
        peersFetched: (peers, action) => {
            Object.assign(peers, action.payload);
        },
        peersRemoved: (peers, action) => {
            peers.length = 0;
        },
        peersSelected: (peers, action) => {
            peers.selected = action.payload;
        },
    },
});

export const { peersFetched, peersRemoved, peersSelected } = slice.actions;

export default slice.reducer;
