import { useSelector, useDispatch } from "react-redux";
import { useContext, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from "react-toastify";

import Stack from "../Stack/Stack.js";
import { cardPickerUpdated } from "../../store/ui.js";
import { cardsSelected } from "../../store/cards.js";
import { WebSocketContext } from "../../WebSocket.js";
import { setUpCardPickerListeners } from "../../utils/drag.js";

import "./CardPicker.css";

const CardPicker = () => {
    const {
        open,
        title,
        body,
        options,
        targetAmount,
        rearrangementOnly,
        discardOnly,
    } = useSelector((state) => state.ui.cardPicker);
    const cards = useSelector((state) => state.entities.cards.all);
    const selected = useSelector((state) => state.entities.cards.selected);
    const playerSelected = useSelector(
        (state) => state.entities.peers.selected
    );
    const ws = useContext(WebSocketContext);
    const { playerId, propertySets, bankCards, bankId, handCards } =
        ws.getCardDrawer(playerSelected);
    const currAmount = selected
        .map((cardId) => cards[cardId].value)
        .reduce((accumulator, currentValue) => {
            return accumulator + currentValue;
        }, 0);

    const dispatch = useDispatch();

    const ackAction = (selected) => {
        ws.ackAction(null, null, null, selected);
    };

    const playCard = (selected) => {
        ws.playCard({ selected });
    };

    const onCardClick = (cardId) => {
        if (discardOnly) {
            if (selected.includes(cardId)) {
                dispatch({
                    type: cardsSelected.type,
                    payload: selected.filter((x) => x !== cardId),
                });
            } else {
                dispatch({
                    type: cardsSelected.type,
                    payload: [...new Set([...selected, cardId])],
                });
            }
        } else if (rearrangementOnly) {
            ws.playCard({ cardId });
        } else if (targetAmount) {
            if (selected.includes(cardId)) {
                dispatch({
                    type: cardsSelected.type,
                    payload: selected.filter((x) => x !== cardId),
                });
            } else {
                dispatch({
                    type: cardsSelected.type,
                    payload: [...new Set([...selected, cardId])],
                });
            }
        } else {
            dispatch({
                type: cardsSelected.type,
                payload: [cardId],
            });
        }
    };

    const handleClose = async (selected, requestCallback) => {
        await dispatch({
            type: cardPickerUpdated.type,
            payload: {
                open: false,
            },
        });
        if (requestCallback) {
            if (targetAmount) ackAction(selected);
            else playCard(selected);
        }
    };

    useEffect(() => {
        setUpCardPickerListeners();
    });

    return (
        <Dialog
            className="card-picker"
            open={open}
            onClose={() => false}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"100%"}
        >
            {title ? (
                <div>
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                </div>
            ) : null}
            <div className="property-sets">
                {
                    /* Displaying property sets of the player */
                    !discardOnly && propertySets && propertySets.length
                        ? propertySets.map((set) =>
                              set.id ? (
                                  <Stack
                                      key={set.id}
                                      setId={set.id}
                                      playerId={playerId}
                                      type={"vertical"}
                                      cards={set.cards}
                                      expanded={false}
                                      onCardClick={onCardClick}
                                  />
                              ) : null
                          )
                        : null
                }
            </div>
            {
                /* Displaying bank cards of the player */
                (targetAmount || rearrangementOnly) &&
                bankCards &&
                bankCards.length ? (
                    <Stack
                        setId={bankId}
                        playerId={playerId}
                        type={"vertical"}
                        cards={bankCards}
                        cash={false}
                        expanded={false}
                        onCardClick={onCardClick}
                    />
                ) : null
            }
            {
                /* Displaying hand cards of the player */
                discardOnly && handCards && handCards.length ? (
                    <Stack
                        setId={bankId}
                        playerId={playerId}
                        type={"horizontal"}
                        cards={handCards}
                        cash={false}
                        expanded={false}
                        onCardClick={onCardClick}
                    />
                ) : null
            }
            <div>
                {options ? (
                    <DialogActions>
                        <DialogContentText id="alert-dialog-description">
                            {body}
                        </DialogContentText>
                        {targetAmount && (
                            <DialogContentText id="alert-dialog-payload">
                                {"$" +
                                    (currAmount ? currAmount : 0) +
                                    "M / $" +
                                    targetAmount +
                                    "M"}
                            </DialogContentText>
                        )}
                        <div className="action-btn-container">
                            {options.map((option) => (
                                <div
                                    className="dialog-btn"
                                    key={option.id}
                                    onClick={async () => {
                                        if (discardOnly) {
                                            const numExtraCards =
                                                handCards.length - 7;
                                            if (
                                                selected.length ===
                                                numExtraCards
                                            ) {
                                                await ws.discardCards(selected);
                                                await ws.skipTurn();
                                            } else {
                                                toast(
                                                    `Please discard exactly ${numExtraCards} cards.`
                                                );
                                            }
                                        } else if (rearrangementOnly) {
                                            ws.skipTurn();
                                        } else if (targetAmount) {
                                            // TODO: currAmount >= targetAmount || selectdCards.length == totalCards
                                            // Forget it, being handled by the backend
                                            currAmount >= targetAmount
                                                ? handleClose(
                                                      option.id,
                                                      option.requestCallback
                                                  )
                                                : toast(
                                                      `Please select cards worth at least $${targetAmount}M.`
                                                  );
                                        } else {
                                            selected.length
                                                ? handleClose(
                                                      option.id,
                                                      option.requestCallback
                                                  )
                                                : toast(
                                                      `Please select a property card to swap out.`
                                                  );
                                        }
                                    }}
                                >
                                    {option.name}
                                </div>
                            ))}
                        </div>
                    </DialogActions>
                ) : null}
            </div>
        </Dialog>
    );
};

export default CardPicker;
