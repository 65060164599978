import { useSelector, useDispatch } from "react-redux";
import { useContext, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { toast } from "react-toastify";

import Stack from "../Stack/Stack.js";
import { setPickerUpdated } from "../../store/ui.js";
import { setsSelected } from "../../store/sets.js";
import { WebSocketContext } from "../../WebSocket.js";
import { setUpCardPickerListeners } from "../../utils/drag.js";

import "./SetPicker.css";

const SetPicker = () => {
    const { open, title, options } = useSelector((state) => state.ui.setPicker);
    const selected = useSelector((state) => state.entities.sets.selected);
    const playerSelected = useSelector(
        (state) => state.entities.peers.selected
    );
    const ws = useContext(WebSocketContext);
    const { playerId, propertySets } = ws.getCardDrawer(playerSelected);
    const dispatch = useDispatch();

    const onSelect = async (selected) => {
        await dispatch({
            type: setsSelected.type,
            payload: selected,
        });
    };

    const handleCancel = async () => {
        console.log("Cancelling");
        await dispatch({
            type: setPickerUpdated.type,
            payload: {
                open: false,
            },
        });
        await dispatch({
            type: setsSelected.type,
            payload: null,
        });
        ws.terminateAction();
    };

    const handleDone = async (requestCallback) => {
        if (!selected) {
            return toast("Please select a set to proceed.");
        }
        await dispatch({
            type: setPickerUpdated.type,
            payload: {
                open: false,
            },
        });
        if (requestCallback) ws.playCard({ selected });
    };

    const handleClose = async (optionId, requestCallback) => {
        if (optionId === "CANCEL") handleCancel();
        else handleDone(requestCallback);
    };

    useEffect(() => {
        setUpCardPickerListeners();
    });

    return (
        <Dialog
            className="card-picker"
            open={open}
            onClose={handleDone}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={"100%"}
        >
            {title ? (
                <div>
                    <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                </div>
            ) : null}
            <div className="property-sets">
                {
                    /* Displaying property sets of the player */
                    propertySets && propertySets.length
                        ? propertySets.map((set) =>
                              set.id ? (
                                  <Stack
                                      key={set.id}
                                      setId={set.id}
                                      playerId={playerId}
                                      type={"vertical"}
                                      cards={set.cards}
                                      expanded={false}
                                      onCardClick={() => onSelect(set.id)}
                                      selected={selected === set.id}
                                  />
                              ) : null
                          )
                        : null
                }
            </div>
            {options ? (
                <DialogActions>
                    <div className="action-btn-container">
                        {options.map((option) => (
                            <div
                                className="dialog-btn"
                                key={option.id}
                                onClick={() =>
                                    handleClose(
                                        option.id,
                                        option.requestCallback
                                    )
                                }
                            >
                                {option.name}
                            </div>
                        ))}
                    </div>
                </DialogActions>
            ) : null}
        </Dialog>
    );
};

export default SetPicker;
