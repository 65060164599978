import React, { createContext, useEffect, useRef } from "react";
import io from "socket.io-client";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { cardsFetched, cardsSelected } from "./store/cards.js";
import {
    actionReceived,
    privateDataFetched,
    publicDataFetched,
} from "./store/game.js";
import {
    cardPickerUpdated,
    setPickerUpdated,
    dialogUpdated,
} from "./store/ui.js";
import { peersFetched, peersSelected } from "./store/peers.js";
import { setsSelected } from "./store/sets.js";
import {
    getAJNCards,
    getHandCards,
    getPropertySets,
    getBankCards,
    isPropertyCard,
    isMoneyCard,
} from "./utils/card.js";
import {
    CASH,
    ACTION,
    DISCARD,
    REARRANGEMENT_PROPERTY,
    REARRANGEMENT_CASH,
} from "./constants/options.js";
import { SELECT_SET, SELECT_COLOR } from "./constants/options.js";

export const WebSocketContext = createContext();

const WebSocketProvider = (props) => {
    const socketRef = useRef(null);
    const actionState = useRef(null);
    const reactionState = useRef(null);
    const allCards = useSelector((state) => state.entities.cards.all);
    const selectedCards = useSelector((state) => state.entities.cards.selected);
    const selectedPlayer = useSelector(
        (state) => state.entities.peers.selected
    );
    const selectedSet = useSelector((state) => state.entities.sets.selected);
    const game = useSelector((state) => state.entities.game.public);
    const publicData = useSelector((state) => state.entities.game.public);
    const privateData = useSelector((state) => state.entities.game.private);
    const action = useSelector((state) => state.entities.game.action);
    const { discardOnly, rearrangementOnly } = useSelector(
        (state) => state.ui.cardPicker
    );

    const dispatch = useDispatch();

    const _playCard = async () => {
        const { cardId, playAs, payload } = actionState.current;
        console.log(
            "_playCard>>> cardId:",
            cardId,
            ", playAs:",
            playAs,
            ", payload:",
            payload
        );
        await socketRef.current.emit(
            "action",
            { card_id: cardId, play_as: playAs, payload: payload },
            (response) => {
                toast(response.message);
                console.log(response);
            }
        );
        actionState.current = null;
    };

    const _playCashCard = async (payload) => {
        await _playCard();
    };

    const _rearrangeCard = async (payload) => {
        const { cardId } = actionState.current;
        const { selected } = payload;
        if (!actionState.current.step) actionState.current.step = 1;
        else actionState.current.step += 1;

        switch (actionState.current.step) {
            case 1:
                if (isPropertyCard(allCards[cardId].type)) {
                    await dispatch({
                        type: dialogUpdated.type,
                        payload: {
                            open: true,
                            collapsable: true,
                            cardId: cardId,
                            options: [SELECT_SET, SELECT_COLOR],
                        },
                    });
                } else {
                    await _playCard();
                }
                break;
            case 2:
                switch (selected) {
                    case "SET":
                        await dispatch({
                            type: setPickerUpdated.type,
                            payload: {
                                open: true,
                                options: [
                                    {
                                        id: "DONE",
                                        name: "DONE",
                                        requestCallback: true,
                                    },
                                    {
                                        id: "CANCEL",
                                        name: "CANCEL",
                                    },
                                ],
                            },
                        });
                        break;
                    case "COLOR":
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardId: cardId,
                                options: allCards[cardId].colors.map(
                                    (color) => ({
                                        id: color,
                                        name: color,
                                        requestCallback: true,
                                    })
                                ),
                            },
                        });
                        break;
                    default:
                        console.log(
                            "Something went wrong!",
                            actionState.current
                        );
                }
                break;
            case 3:
                if (selected.startsWith("ps")) {
                    actionState.current = {
                        ...actionState.current,
                        payload: {
                            set: {
                                set_id: selected,
                            },
                        },
                    };
                } else {
                    actionState.current = {
                        ...actionState.current,
                        payload: {
                            set: { color: selected },
                        },
                    };
                }
                // Unselect the set
                await dispatch({
                    type: setsSelected.type,
                    payload: null,
                });
                await _playCard();
                break;
            default:
                // Unselect the set
                await dispatch({
                    type: setsSelected.type,
                    payload: null,
                });
                console.log("Something went wrong!", actionState.current);
                actionState.current = null;
        }
    };

    const _discardCard = async () => {
        const { cardId, playAs } = actionState.current;
        await socketRef.current.emit(
            "action",
            {
                card_ids: [cardId],
                play_as: playAs,
            },
            (response) => {
                console.log(response);
                toast(response.message);
            }
        );
        actionState.current = null;
    };

    const _playActionCard = async ({ selected, ...others }) => {
        const { cardId, setId, playerId } = actionState.current;
        const cardCode = allCards[cardId].code;

        if (!actionState.current.step) actionState.current.step = 1;
        else actionState.current.step += 1;

        let handCards, adrCards;
        let propertySets;

        switch (cardCode) {
            /* Basic Property Cards */
            case "PBr":
            case "PDb":
            case "PLg":
            case "PBl":
            case "POr":
            case "PRd":
            case "PDg":
            case "PPk":
            case "PYl":
            case "PLb":
                switch (actionState.current.step) {
                    case 1:
                        // Ask user to either select a set or skip to create a new set
                        propertySets = getPropertySets(
                            publicData,
                            privateData.player_id
                        );
                        let options = [
                            {
                                id: "SKIP",
                                name: "Create New Set",
                                requestCallback: true,
                            },
                        ];
                        if (propertySets.length)
                            options.push({
                                id: "SET",
                                name: "Select Set",
                                requestCallback: true,
                            });
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: options,
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "SET":
                                await dispatch({
                                    type: setPickerUpdated.type,
                                    payload: {
                                        open: true,
                                        options: [
                                            {
                                                id: "DONE",
                                                name: "DONE",
                                                requestCallback: true,
                                            },
                                            {
                                                id: "CANCEL",
                                                name: "CANCEL",
                                            },
                                        ],
                                    },
                                });
                                break;
                            case "SKIP":
                                // Since it is final step, send the action to backend.
                                await _playCard();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 3:
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                set: {
                                    set_id: selected,
                                },
                            },
                        };
                        // Unselect the set
                        await dispatch({
                            type: setsSelected.type,
                            payload: null,
                        });
                        await _playCard();
                        break;
                    default:
                        // Unselect the set
                        await dispatch({
                            type: setsSelected.type,
                            payload: null,
                        });
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            /* Property Wild Cards */
            case "PWCXX":
            case "PWCRdYl":
            case "PWCOrPk":
            case "PWCLbBl":
            case "PWCDgBl":
            case "PWCLgBl":
            case "PWCLbBr":
            case "PWCDbDg":
                // Set ID or color
                switch (actionState.current.step) {
                    case 1:
                        // Ask user to either select a set or skip to create a new set
                        propertySets = getPropertySets(
                            publicData,
                            privateData.player_id
                        );
                        let options = [
                            {
                                id: "COLOR",
                                name: "Create New Set",
                                requestCallback: true,
                            },
                        ];
                        if (propertySets.length)
                            options.push({
                                id: "SET",
                                name: "Select Set",
                                requestCallback: true,
                            });
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: options,
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "SET":
                                await dispatch({
                                    type: setPickerUpdated.type,
                                    payload: {
                                        open: true,
                                        options: [
                                            {
                                                id: "DONE",
                                                name: "DONE",
                                                requestCallback: true,
                                            },
                                            {
                                                id: "CANCEL",
                                                name: "CANCEL",
                                            },
                                        ],
                                    },
                                });
                                break;
                            case "COLOR":
                                await dispatch({
                                    type: dialogUpdated.type,
                                    collapsable: true,
                                    payload: {
                                        open: true,
                                        cardCode: cardCode,
                                        cardId: cardId,
                                        setId: setId,
                                        playerId: playerId,
                                        options: allCards[cardId].colors.map(
                                            (color) => ({
                                                id: color,
                                                name: color,
                                                requestCallback: true,
                                            })
                                        ),
                                    },
                                });
                                break;
                            default:
                                console.log(
                                    "Something went wrong!",
                                    actionState.current
                                );
                        }
                        break;
                    case 3:
                        if (selected.startsWith("ps")) {
                            actionState.current = {
                                ...actionState.current,
                                payload: {
                                    set: {
                                        set_id: selected,
                                    },
                                },
                            };
                            // Unselect the set
                            await dispatch({
                                type: setsSelected.type,
                                payload: null,
                            });
                        } else {
                            actionState.current = {
                                ...actionState.current,
                                payload: {
                                    set: { color: selected },
                                },
                            };
                        }
                        await _playCard();
                        break;
                    default:
                        // Unselect the set
                        await dispatch({
                            type: setsSelected.type,
                            payload: null,
                        });
                        console.log(
                            "Something went wrong!",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            /* Action Cards */
            case "ASD" /* Sly Deal */:
                switch (actionState.current.step) {
                    case 1:
                        // Facilitate opponent selection
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                body: "Select the opponent you want to steal a card from.",
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: Object.values(game.players)
                                    .filter(
                                        (player) =>
                                            player.player_id !==
                                            privateData.player_id
                                    )
                                    .map((player) => ({
                                        id: player.player_id,
                                        name: player.username,
                                        requestCallback: true,
                                    })),
                            },
                        });
                        break;
                    case 2:
                        const opponentPropertySets = getPropertySets(
                            publicData,
                            selected
                        );

                        if (opponentPropertySets.length) {
                            // Select the opponent
                            await dispatch({
                                type: peersSelected.type,
                                payload: selected,
                            });
                            await dispatch({
                                type: cardPickerUpdated.type,
                                payload: {
                                    open: true,
                                    collapsable: false,
                                    title: "Sly Deal",
                                    body: "Select the card you want to steal.",
                                    targetAmount: null,
                                    options: [
                                        {
                                            id: "STEAL",
                                            name: "STEAL",
                                            requestCallback: true,
                                        },
                                    ],
                                },
                            });
                        } else {
                            toast(
                                "The selected opponent doesn't have any property card."
                            );
                            // Unselect the opponent
                            await dispatch({
                                type: peersSelected.type,
                                payload: null,
                            });
                            actionState.current = null;
                        }
                        break;
                    case 3:
                        // Save input from step 3.
                        actionState.current.payload = {
                            ...actionState.current.payload,
                            player_id: selectedPlayer,
                            card_id: selectedCards[0],
                        };
                        // Unselect the opponent
                        await dispatch({
                            type: peersSelected.type,
                            payload: null,
                        });
                        // Unselect the card
                        await dispatch({
                            type: cardsSelected.type,
                            payload: [],
                        });
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        actionState.current = null;
                        // Unselect the opponent
                        await dispatch({
                            type: peersSelected.type,
                            payload: null,
                        });
                        // Unselect the card
                        await dispatch({
                            type: cardsSelected.type,
                            payload: [],
                        });
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                }
                break;
            case "AFD" /* Forced Deal */:
                switch (actionState.current.step) {
                    case 1:
                        const playerPropertySets = getPropertySets(
                            publicData,
                            privateData.player_id
                        );
                        if (playerPropertySets.length) {
                            await dispatch({
                                type: cardPickerUpdated.type,
                                payload: {
                                    open: true,
                                    collapsable: false,
                                    title: "Forced Deal: Swapping Out",
                                    body: "Select the card you want to swap out.",
                                    targetAmount: null,
                                    options: [
                                        {
                                            id: "SWAP_OUT",
                                            name: "SWAP OUT",
                                            requestCallback: true,
                                        },
                                    ],
                                },
                            });
                        } else {
                            toast(
                                "You don't have a property card to swap out."
                            );
                            actionState.current = null;
                        }
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                swap_card_id: selectedCards[0],
                            },
                        };
                        // Unselect the card
                        await dispatch({
                            type: cardsSelected.type,
                            payload: [],
                        });
                        // Facilitate opponent selection
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                body: "Select the opponent you want to swap the card with.",
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: Object.values(game.players)
                                    .filter(
                                        (player) =>
                                            player.player_id !==
                                            privateData.player_id
                                    )
                                    .map((player) => ({
                                        id: player.player_id,
                                        name: player.username,
                                        requestCallback: true,
                                    })),
                            },
                        });
                        break;
                    case 3:
                        const opponentPropertySets = getPropertySets(
                            publicData,
                            selected
                        );

                        if (opponentPropertySets.length) {
                            // Select the opponent
                            await dispatch({
                                type: peersSelected.type,
                                payload: selected,
                            });
                            await dispatch({
                                type: cardPickerUpdated.type,
                                payload: {
                                    open: true,
                                    collapsable: false,
                                    title: "Forced Deal: Swapping In",
                                    body: "Select the card you want to swap in.",
                                    targetAmount: null,
                                    options: [
                                        {
                                            id: "SWAP_IN",
                                            name: "SWAP IN",
                                            requestCallback: true,
                                        },
                                    ],
                                },
                            });
                        } else {
                            toast(
                                "The selected opponent doesn't have any property card."
                            );
                            // Unselect the opponent
                            await dispatch({
                                type: peersSelected.type,
                                payload: null,
                            });
                            actionState.current = null;
                        }
                        break;
                    case 4:
                        // Save input from step 3.
                        actionState.current.payload = {
                            ...actionState.current.payload,
                            player_id: selectedPlayer,
                            card_id: selectedCards[0],
                        };
                        // Unselect the opponent
                        await dispatch({
                            type: peersSelected.type,
                            payload: null,
                        });
                        // Unselect the card
                        await dispatch({
                            type: cardsSelected.type,
                            payload: [],
                        });
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        actionState.current = null;
                        // Unselect the opponent
                        await dispatch({
                            type: peersSelected.type,
                            payload: null,
                        });
                        // Unselect the card
                        await dispatch({
                            type: cardsSelected.type,
                            payload: [],
                        });
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                }
                break;
            case "ADB" /* Deal Breaker */:
                switch (actionState.current.step) {
                    case 1:
                        // Facilitate opponent selection
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                body: "Select the opponent you want to break a deal from.",
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: Object.values(game.players)
                                    .filter(
                                        (player) =>
                                            player.player_id !==
                                            privateData.player_id
                                    )
                                    .map((player) => ({
                                        id: player.player_id,
                                        name: player.username,
                                        requestCallback: true,
                                    })),
                            },
                        });
                        break;
                    case 2:
                        const opponentPropertySets = getPropertySets(
                            publicData,
                            selected
                        );

                        if (opponentPropertySets.length) {
                            // Select the opponent
                            await dispatch({
                                type: peersSelected.type,
                                payload: selected,
                            });
                            await dispatch({
                                type: setPickerUpdated.type,
                                payload: {
                                    open: true,
                                    collapsable: false,
                                    title: "Deal Breaker",
                                    body: "Select the full set you want.",
                                    targetAmount: null,
                                    options: [
                                        {
                                            id: "DEAL_BREAKER",
                                            name: "BREAK THE DEAL",
                                            requestCallback: true,
                                        },
                                    ],
                                },
                            });
                        } else {
                            toast(
                                "The selected opponent doesn't have any property set."
                            );
                            // Unselect the opponent
                            await dispatch({
                                type: peersSelected.type,
                                payload: null,
                            });
                            actionState.current = null;
                        }
                        break;
                    case 3:
                        // Save input from step 3.
                        actionState.current.payload = {
                            ...actionState.current.payload,
                            player_id: selectedPlayer,
                            set: { set_id: selectedSet },
                        };
                        // Unselect the opponent
                        await dispatch({
                            type: peersSelected.type,
                            payload: null,
                        });
                        // Unselect the set
                        await dispatch({
                            type: setsSelected.type,
                            payload: null,
                        });
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        // Unselect the opponent
                        await dispatch({
                            type: peersSelected.type,
                            payload: null,
                        });
                        // Unselect the card
                        await dispatch({
                            type: setsSelected.type,
                            payload: null,
                        });
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            case "ADC" /* Debt Collector */:
                switch (actionState.current.step) {
                    case 1:
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                body: "Select a player to collect debt from.",
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: Object.values(game.players)
                                    .filter(
                                        (player) =>
                                            player.player_id !==
                                            privateData.player_id
                                    )
                                    .map((player) => ({
                                        id: player.player_id,
                                        name: player.username,
                                        requestCallback: true,
                                    })),
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                player_id: selected,
                            },
                        };
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        actionState.current = null;
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                }
                break;
            case "AIB" /* Birthday */:
            case "APG" /* Pass Go */:
                await _playCard();
                break;
            /* Rent Card (Two Colors) */
            case "ARTDbDg":
            case "ARTLbBr":
            case "ARTLgBl":
            case "ARTOrPk":
            case "ARTRdYl":
                handCards = Object.values(privateData.private.sets).filter(
                    (set) => set.id.startsWith("hs")
                )[0].cards;
                adrCards = handCards.filter((cid) => cid.startsWith("cd_adr"));
                switch (actionState.current.step) {
                    case 1:
                        await dispatch({
                            type: setPickerUpdated.type,
                            payload: {
                                open: true,
                                options: [
                                    {
                                        id: "DONE",
                                        name: "DONE",
                                        requestCallback: true,
                                    },
                                    {
                                        id: "CANCEL",
                                        name: "CANCEL",
                                    },
                                ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                set: { set_id: selectedSet },
                            },
                        };
                        // Unselect the set
                        await dispatch({
                            type: setsSelected.type,
                            payload: null,
                        });
                        // If no double rent card, play action
                        if (adrCards.length === 0) {
                            // Skip the 3rd and the 4th steps
                            actionState.current.step = 4;
                            await playCard({ selected: null });
                            break;
                        }

                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                body: "Do you want to double the rent?",
                                options: [
                                    {
                                        id: "DOUBLE_RENT",
                                        name: "YES",
                                        requestCallback: true,
                                    },
                                    {
                                        id: "SKIP",
                                        name: "NO",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 3:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards =
                                    [adrCards[0]];
                                if (adrCards.length == 1) {
                                    // Skip the 4th step
                                    actionState.current.step = 4;
                                    await playCard({ selected: null });
                                    break;
                                }

                                await dispatch({
                                    type: dialogUpdated.type,
                                    payload: {
                                        open: true,
                                        collapsable: true,
                                        cardCode: cardCode,
                                        cardId: cardId,
                                        setId: setId,
                                        body: "Do you want to double the rent once more?",
                                        playerId: playerId,
                                        options: [
                                            {
                                                id: "DOUBLE_RENT",
                                                name: "YES",
                                                requestCallback: true,
                                            },
                                            {
                                                id: "SKIP",
                                                name: "NO",
                                                requestCallback: true,
                                            },
                                        ],
                                    },
                                });
                                break;
                            case "SKIP":
                                // Skip the 4th step
                                actionState.current.step = 4;
                                await playCard({ selected: null });
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 4:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards.push(
                                    adrCards[1]
                                );
                                await playCard({ selected: null });
                                break;
                            case "SKIP":
                                await playCard({ selected: null });
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 5:
                        await _playCard();
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            /* Rent Card (All Colors) */
            case "ARTXX":
                handCards = Object.values(privateData.private.sets).filter(
                    (set) => set.id.startsWith("hs")
                )[0].cards;
                adrCards = handCards.filter((cid) => cid.startsWith("cd_adr"));
                switch (actionState.current.step) {
                    case 1:
                        await dispatch({
                            type: setPickerUpdated.type,
                            payload: {
                                open: true,
                                options: [
                                    {
                                        id: "DONE",
                                        name: "DONE",
                                        requestCallback: true,
                                    },
                                    {
                                        id: "CANCEL",
                                        name: "CANCEL",
                                    },
                                ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                set: { set_id: selectedSet },
                            },
                        };
                        // Unselect the set
                        await dispatch({
                            type: setsSelected.type,
                            payload: null,
                        });
                        // If no double rent card, play action
                        if (adrCards.length === 0) {
                            // Skip the 3rd and the 4th steps
                            actionState.current.step = 4;
                            await playCard({ selected: null });
                            break;
                        }

                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: true,
                            payload: {
                                open: true,
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                body: "Do you want to double the rent?",
                                options: [
                                    {
                                        id: "DOUBLE_RENT",
                                        name: "YES",
                                        requestCallback: true,
                                    },
                                    {
                                        id: "SKIP",
                                        name: "NO",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 3:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards =
                                    [adrCards[0]];
                                if (adrCards.length == 1) {
                                    // Skip the 4th step
                                    actionState.current.step = 4;
                                    await playCard({ selected: null });
                                    break;
                                }

                                await dispatch({
                                    type: dialogUpdated.type,
                                    payload: {
                                        open: true,
                                        collapsable: true,
                                        cardCode: cardCode,
                                        cardId: cardId,
                                        setId: setId,
                                        body: "Do you want to double the rent once more?",
                                        playerId: playerId,
                                        options: [
                                            {
                                                id: "DOUBLE_RENT",
                                                name: "YES",
                                                requestCallback: true,
                                            },
                                            {
                                                id: "SKIP",
                                                name: "NO",
                                                requestCallback: true,
                                            },
                                        ],
                                    },
                                });
                                break;
                            case "SKIP":
                                // Skip the 4th step
                                actionState.current.step = 4;
                                await playCard({ selected: null });
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 4:
                        switch (selected) {
                            case "DOUBLE_RENT":
                                actionState.current.payload.set.multiplier_cards.push(
                                    adrCards[1]
                                );
                                await playCard({ selected: null });
                                break;
                            case "SKIP":
                                await playCard({ selected: null });
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong! ",
                                    actionState.current
                                );
                                actionState.current = null;
                        }
                        break;
                    case 5:
                        await dispatch({
                            type: dialogUpdated.type,
                            payload: {
                                open: true,
                                collapsable: true,
                                body: "Select a player to collect rent from.",
                                cardCode: cardCode,
                                cardId: cardId,
                                setId: setId,
                                playerId: playerId,
                                options: Object.values(game.players)
                                    .filter(
                                        (player) =>
                                            player.player_id !==
                                            privateData.player_id
                                    )
                                    .map((player) => ({
                                        id: player.player_id,
                                        name: player.username,
                                        requestCallback: true,
                                    })),
                            },
                        });
                        break;
                    case 6:
                        // Save input from step 5.
                        actionState.current.payload.player_id = selected;
                        await _playCard();
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            case "AHT" /* Hotel */:
            case "AHS" /* House */:
                switch (actionState.current.step) {
                    case 1:
                        // Ask user to either select a set
                        propertySets = getPropertySets(
                            publicData,
                            privateData.player_id
                        );

                        if (propertySets.length) {
                            await dispatch({
                                type: setPickerUpdated.type,
                                payload: {
                                    open: true,
                                    options: [
                                        {
                                            id: "DONE",
                                            name: "DONE",
                                            requestCallback: true,
                                        },
                                        {
                                            id: "CANCEL",
                                            name: "CANCEL",
                                        },
                                    ],
                                },
                            });
                            break;
                        } else {
                            toast("Create a full set first to place a house.");
                            actionState.current = null;
                        }
                        break;
                    case 2:
                        // Save input from step 1.
                        actionState.current = {
                            ...actionState.current,
                            payload: {
                                set: { set_id: selected },
                            },
                        };
                        // Unselect the set
                        await dispatch({
                            type: setsSelected.type,
                            payload: null,
                        });
                        // Since it is final step, send the action to backend.
                        await _playCard();
                        break;
                    default:
                        // Unselect the set
                        await dispatch({
                            type: setsSelected.type,
                            payload: null,
                        });
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong! ",
                            actionState.current
                        );
                        actionState.current = null;
                }
                break;
            case "ADR" /* Double Rent */:
                toast("This card cannot initiate an action.");
                actionState.current = null;
                break;
            case "AJN" /* Just Say No */:
                toast("This card cannot initiate an action.");
                actionState.current = null;
                break;
            default:
                toast("This card cannot be played as action card.");
                actionState.current = null;
        }
    };

    const playCard = async (payload) => {
        if (!actionState.current) {
            actionState.current = payload;
            const { cardId } = actionState.current;
            const handCards = getHandCards(privateData);
            const playerId = privateData.player_id;
            const propertySets = getPropertySets(publicData, playerId);
            const bankCards = getBankCards(privateData);
            const cardType = allCards[cardId].type;
            let options = [];

            if (handCards.includes(cardId)) {
                if (isMoneyCard(cardType)) {
                    // options = [CASH, DISCARD];
                    options = [CASH];
                } else if (isPropertyCard(cardType)) {
                    //options = [ACTION, DISCARD];
                    options = [ACTION];
                } else {
                    // options = [CASH, ACTION, DISCARD];
                    options = [CASH, ACTION];
                }
            } else if (bankCards.includes(cardId)) {
                options = [REARRANGEMENT_CASH];
            } else if (propertySets.find((p) => p.cards.includes(cardId))) {
                actionState.current.setColor = propertySets.find((p) =>
                    p.cards.includes(cardId)
                ).color;
                options = [REARRANGEMENT_PROPERTY];
            }

            await dispatch({
                type: dialogUpdated.type,
                payload: {
                    open: true,
                    collapsable: true,
                    cardId: cardId,
                    setColor: actionState.current.setColor,
                    options: options,
                },
            });
        } else {
            if (!actionState.current.playAs) {
                const { selected } = payload;
                actionState.current.playAs = selected;
            }

            switch (actionState.current.playAs) {
                case "CASH":
                    _playCashCard(payload);
                    break;
                case "REARRANGEMENT":
                    _rearrangeCard(payload);
                    break;
                case "DISCARD":
                    _discardCard(payload);
                    break;
                case "ACTION":
                default:
                    _playActionCard(payload);
                    break;
            }
        }
        console.log("Action state: ", actionState.current);
    };

    const discardCards = async (cardIds) => {
        actionState.current = null;
        await socketRef.current.emit(
            "action",
            {
                card_ids: cardIds,
                play_as: "DISCARD",
            },
            (response) => {
                console.log(response);
                toast(response.message);
            }
        );
    };

    const _ackAction = async () => {
        const { card_id, play_as, payload } = reactionState.current;
        await socketRef.current.emit(
            "action",
            { card_id, play_as, payload },
            (response) => {
                console.log(response);
                toast(response.message);
            }
        );
        reactionState.current = null;
        dispatch({
            type: cardsSelected.type,
            payload: [],
        });
        dispatch({
            type: actionReceived.type,
            payload: null,
        });
    };

    const ackAction = async (
        actionName,
        cardId,
        targetAmount,
        selected,
        message
    ) => {
        if (!reactionState.current) {
            reactionState.current = {
                play_as: "REPLY",
                actionCard: cardId,
                actionName,
                targetAmount,
                step: 1,
            };
        } else {
            reactionState.current.step += 1;
        }

        console.log("reactionState: ", reactionState.current);

        const AJNCards = getAJNCards(privateData);

        switch (reactionState.current.actionName) {
            case "SLY_DEAL":
            case "FORCED_DEAL":
            case "DEAL_BREAKER":
                switch (reactionState.current.step) {
                    case 1:
                        // Ask user to either play just say no or ack
                        await dispatch({
                            type: dialogUpdated.type,
                            collapsable: false,
                            payload: {
                                open: true,
                                cardCode:
                                    allCards[reactionState.current.actionCard]
                                        .code,
                                cardId: reactionState.current.actionCard,
                                body: message,
                                options: AJNCards.length
                                    ? [
                                          {
                                              id: "JSN",
                                              name: "Just Say No",
                                              requestCallback: true,
                                          },
                                          {
                                              id: "ACK",
                                              name: "Acknowledge",
                                              requestCallback: true,
                                          },
                                      ]
                                    : [
                                          {
                                              id: "ACK",
                                              name: "Acknowledge",
                                              requestCallback: true,
                                          },
                                      ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "JSN":
                                reactionState.current = {
                                    ...reactionState.current,
                                    card_id: AJNCards[0],
                                };
                                break;
                            case "ACK":
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    reactionState.current
                                );
                                reactionState.current = null;
                        }
                        await _ackAction();
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong!",
                            reactionState.current
                        );
                        reactionState.current = null;
                }
                break;
            case "DEBT_COLLECTOR":
            case "ITS_MY_BIRTHDAY":
            case "RENT":
                // Skip to step 2 if the user has no JSN card
                if (!AJNCards.length && reactionState.current.step === 1) {
                    reactionState.current.step += 1;
                    selected = "PAY";
                }
                switch (reactionState.current.step) {
                    case 1:
                        // Ask user to either play just say no or ack
                        await dispatch({
                            type: dialogUpdated.type,
                            payload: {
                                open: true,
                                collapsable: false,
                                cardCode: null,
                                cardId: reactionState.current.actionCard,
                                body: message,
                                options: [
                                    {
                                        id: "JSN",
                                        name: "Just Say No",
                                        requestCallback: true,
                                    },
                                    {
                                        id: "PAY",
                                        name: "Pay",
                                        requestCallback: true,
                                    },
                                ],
                            },
                        });
                        break;
                    case 2:
                        // Save input from step 1.
                        switch (selected) {
                            case "JSN":
                                reactionState.current = {
                                    ...reactionState.current,
                                    card_id: AJNCards[0],
                                };
                                await _ackAction();
                                break;
                            case "PAY":
                                await dispatch({
                                    type: cardPickerUpdated.type,
                                    payload: {
                                        open: true,
                                        collapsable: false,
                                        title: message,
                                        body: "Select cards to pay.",
                                        targetAmount:
                                            reactionState.current.targetAmount,
                                        options: [
                                            {
                                                id: "PAY",
                                                name: "PAY",
                                                requestCallback: true,
                                            },
                                        ],
                                    },
                                });
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    reactionState.current
                                );
                                reactionState.current = null;
                        }
                        break;
                    case 3:
                        switch (selected) {
                            case "PAY":
                                reactionState.current.payload = {
                                    card_ids: selectedCards,
                                };
                                await _ackAction();
                                break;
                            default:
                                toast("Something went wrong!");
                                console.log(
                                    "Something went wrong!",
                                    reactionState.current
                                );
                                reactionState.current = null;
                        }
                        break;
                    default:
                        toast("Something went wrong!");
                        console.log(
                            "Something went wrong!",
                            reactionState.current
                        );
                        reactionState.current = null;
                }
                break;
            default:
                if (cardId) {
                    await dispatch({
                        type: dialogUpdated.type,
                        payload: {
                            open: true,
                            cardCode: allCards[cardId].code,
                            body: message,
                        },
                    });
                }
                console.log("Unrecognised reaction!", reactionState.current);
                reactionState.current = null;
        }
    };

    const startGame = async (roomId, callback) => {
        socketRef.current.emit(
            "start_game",
            {
                room_id: roomId,
            },
            (res) => {
                console.log(res);
                callback(res);
            }
        );
    };

    const terminateAction = () => {
        actionState.current = null;
        console.log("Action state cleared: ", actionState.current);
    };

    const skipTurn = async () => {
        await socketRef.current.emit(
            "action",
            {
                skip_turn: true,
                play_as: "ACTION",
            },
            (res) => {
                dispatch({
                    type: cardsSelected.type,
                    payload: [],
                });
                if (res && res.message.includes("discard")) {
                    // Move the discard logic to backend, backend should update
                    // turn to maybe 4 so that we can skip the hardcoded "7" below
                    // and the message parsing above
                    const handCards = getHandCards(privateData);
                    dispatch({
                        type: cardPickerUpdated.type,
                        payload: {
                            open: true,
                            title: "Discard Cards",
                            body: `Please discard ${
                                handCards.length - 7
                            } cards.`,
                            rearrangementOnly: false,
                            discardOnly: true,
                            options: [
                                {
                                    id: "DONE",
                                    name: "END TURN",
                                },
                            ],
                        },
                    });
                } else {
                    console.log(res);
                    dispatch({
                        type: cardPickerUpdated.type,
                        payload: {
                            open: false,
                        },
                    });
                }
            }
        );
    };

    const getCardDrawer = (playerSelected) => {
        if (playerSelected) {
            return {
                playerId: playerSelected,
                propertySets: Object.values(
                    game.players[playerSelected].public.sets
                ),
            };
        } else {
            return {
                playerId: privateData.player_id,
                propertySets: Object.values(
                    game.players[privateData.player_id].public.sets
                ),
                bankCards: Object.values(privateData.private.sets).filter(
                    (set) => set.id.startsWith("bk")
                )[0].cards,
                bankId: Object.values(privateData.private.sets).filter((set) =>
                    set.id.startsWith("bk")
                )[0].id,
                handCards: getHandCards(privateData),
            };
        }
    };

    useEffect(() => {
        if (!socketRef.current) {
            const authToken = localStorage.getItem("auth");
            socketRef.current = io("https://api.dealmonopoly.com", {
                path: "/socket/game-service",
                extraHeaders: {
                    Authorization: authToken,
                },
            });

            socketRef.current.on("gamedata", (response) => {
                console.log("Game data listener received: ", response);
                if (!response) return;
                // If the response has player_id field, it is private data
                if (response.player_id) {
                    dispatch({
                        type: privateDataFetched.type,
                        payload: response,
                    });
                } else
                    dispatch({
                        type: publicDataFetched.type,
                        payload: response,
                    });
                if (!localStorage.getItem("gameStarted")) {
                    localStorage.setItem("gameStarted", true);
                    window.location.reload();
                }
            });

            socketRef.current.on("metadata", (response) => {
                console.log("Meta data listener received: ", response);
                dispatch({
                    type: cardsFetched.type,
                    payload: response,
                });
            });

            socketRef.current.on("notification", (response) => {
                console.log("Notification listener received: ", response);
                toast(response.message);
                socketRef.current.emit("notification", {
                    notification_id: response.id,
                });
            });

            socketRef.current.on("action", (response) => {
                console.log("Action listener received:", response);
                dispatch({
                    type: actionReceived.type,
                    payload: response,
                });
            });

            socketRef.current.on("players", async (response) => {
                await dispatch({
                    type: peersFetched.type,
                    payload: response,
                });
                console.log("Players: ", response);
            });

            socketRef.current.on("connect", () => {
                console.log(`Connected: ${socketRef.current.id}`);
            });

            socketRef.current.on("connect_error", (reason) => {
                console.log(`Connection error: ${reason}`);
                // Maybe the token has expired, reset and retry
                // if (localStorage.getItem("auth")) {
                // TODO: if error message shows that its an expired token, clear else retry
                // TODO: below goes in if part
                // localStorage.removeItem("auth");
                // localStorage.removeItem("gameStarted");
                // window.location.reload();
                // TODO: below goes in else part
                // setTimeout(() => {
                //   socketRef.current.connect();
                // }, 1000);
                // }
            });

            socketRef.current.on("disconnect", (reason) => {
                console.log(`Disconnected: ${reason}`);
            });

            // socketRef.current.onAny((eventName, ...args) => {
            //   console.log(eventName, args);
            // });
        } else if (allCards && game && privateData) {
            if (action && !action.is_fulfilled && game.turn.active_action) {
                // Ack action only if this user is liable
                if (
                    game.turn.active_action.liable_players &&
                    game.turn.active_action.liable_players.filter(
                        (player) => player.player_id === privateData.player_id
                    )[0]
                ) {
                    ackAction(
                        action.action_name,
                        action.action_card,
                        action.value,
                        null,
                        action.message
                    );
                }
            }

            if (
                game.turn.turn === privateData.player_id &&
                game.turn.turn_number === 3
            ) {
                dispatch({
                    type: cardPickerUpdated.type,
                    payload: {
                        open: true,
                        title: "Rearrange Cards",
                        body: "Please end turn when done with rearrangement.",
                        rearrangementOnly: true,
                        options: [
                            {
                                id: "DONE",
                                name: "END TURN",
                            },
                        ],
                    },
                });
            }
        }
    }, [allCards, game, privateData, action, dispatch]);

    return (
        <WebSocketContext.Provider
            value={{
                playCard,
                terminateAction,
                ackAction,
                skipTurn,
                startGame,
                getCardDrawer,
                discardCards,
            }}
        >
            {props.children}
        </WebSocketContext.Provider>
    );
};

export default WebSocketProvider;
